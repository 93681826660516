@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700');

$base-spacing-unit: 24px;
$half-spacing-unit: $base-spacing-unit / 2;

$color-alpha: #1772FF;
$color-form-highlight: #e8e3e3;

.table {
    width:100%;
}

.table__header {
	display:flex;
	width:100%;
	background:#84BD00;
	padding:($half-spacing-unit * 1.5) 0;

	a {
		font-weight: 600;
	}
}

.table__row {
	width:100%;
	display: flex;
	padding:($half-spacing-unit * 1.5) 0;
	
	&:nth-of-type(odd) {
		background:$color-form-highlight;
	}
}

.table_collapse {
	break-after: always;
}

.table__data, .header__item {
	flex: 1 1 20%;
}

.table__data {
	padding: 0 0 0 1.6em;
	
	&__ellipsis {
		padding: 0 0 0 1.6em;
		flex: 1 1 20%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.table__data__middle {
	text-align: center;
	flex: 1 1 20%;
}

.header__item {
	text-align:center;
}

.header__item {
	text-transform:uppercase;
}

.filter__link {
	color:white;
	text-decoration: none;
	position:relative;
	display:inline-block;
	padding-left:$base-spacing-unit;
	padding-right:$base-spacing-unit;
	
	&::after {
		content:'';
		position:absolute;
		right:-($half-spacing-unit * 1.5);
		color:white;
		font-size:$half-spacing-unit;
		top: 50%;
		transform: translateY(-50%);
	}
	
	&.desc::after {
		content: '(desc)';
	}

	&.asc::after {
		content: '(asc)';
	}
	
}