.container {
    overflow: auto;
    align-items: center;
    flex-wrap: wrap;

    &__zebra {
        &:nth-of-type(odd) {
            background: #e8e3e3;
        }
    }
}