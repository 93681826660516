.page {
    max-width: 100vw;
    overflow: hidden;
    min-height: 100vh;

    &__wrapper {
        display: flex;

        &__content {
            flex: 1;
            padding: 2em;
        }
    
        &__sidebar {
            flex: 0 0 auto;
            min-height: 100vh !important;
            background-color: #84BD00;
        }
    }
}